import PropTypes from 'prop-types'
import {useState} from 'react'
import {List, Map} from 'immutable'

import ConfirmationCloser from '../../../../../shared_components/ConfirmationCloser'
import DeputyUnlockPermission from './DeputyUnlockPermission'
import DeputyUnlockPermissionsConfirmation from './DeputyUnlockPermissionsConfirmation'
import Modals from '../../../../../shared_components/core/modals/Modals'

import {addPossessive} from '../../../../../lib/tools'
import {findResourceById} from '../../../../../lib/plan_data/userData'
import {humanizedFullName} from '../../../../../lib/nameTools'
import {ModalHeader} from '../../../../../shared_components/core/modals/ModalHeaders'
import {UPDATE_DEPUTY_UNLOCK_PERMISSIONS} from '../../../../../graphql/mutations/deputy'
import {useMutation} from '@apollo/client'
import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'
import {useScoreLedgerEventLogger} from '../../../../../lib/ScoreLedgerEventLogger'
import Logger from '../../../../../lib/NewLogger'

import './manageDeputyUnlockPermissionsController.scss'

const ManageDeputyUnlockPermissionsController = props => {
  const [confirmClose, setConfirmClose] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [showDeputyUnlockConfirmation, setShowDeputyUnlockConfirmation] = useState(false)
  const {alwaysNotify} = useNotificationContext()
  const {logWithDelayedNotification, logWithNotification} = useScoreLedgerEventLogger(props.userConfig)

  const deputyId = props.params.deputyOwnershipId
  const deputy = findResourceById({resourceList: props.deputies, id: deputyId})
  const deputyName = humanizedFullName(deputy.get('ownable'))

  const [updateDeputyUnlockPermission] = useMutation(UPDATE_DEPUTY_UNLOCK_PERMISSIONS)

  const logEvent = name => {
    Logger.log({
      name,
      payload: {
        context: 'deputy_permissions_unlocker'
      }
    })
  }

  const closeModal = () => {
    logEvent('dismissed_deputy_permissions')
    props.exit()
  }

  const unlockPermissionsIsUnchanged = unlockPermissionsData => (
    unlockPermissionsData['death-reporter'] === deputy.get('death-reporter') &&
      unlockPermissionsData['death-verification-period'] === deputy.get('death-verification-period')
  )

  const onUpdateSuccess = isDeathReporter => {
    if (isDeathReporter) {
      setShowDeputyUnlockConfirmation(true)
      logWithNotification.updatedDeputyPermissions()
    } else {
      alwaysNotify.shortSuccess(`${addPossessive(deputyName)} permissions were updated`)
      logWithDelayedNotification.updatedDeputyPermissions()
      props.exit()
    }
  }

  const submit = unlockPermissionsData => {
    setProcessing(true)

    if (unlockPermissionsIsUnchanged(unlockPermissionsData)) {
      closeModal()
    } else {
      logEvent('saved_deputy_permissions')

      updateDeputyUnlockPermission({
        variables: {
          input: {
            deputyId,
            deathReporter: unlockPermissionsData['death-reporter'],
            deathVerificationPeriod: unlockPermissionsData['death-verification-period']
          }
        },
        onCompleted: data => {
          onUpdateSuccess(data.updateDeputyUnlockPermission.deathReporter)
        },
        onError: () => {
          alwaysNotify.shortError(`Unable to update ${addPossessive(deputyName)} permissions. Please try again.`)
        }
      })
    }
  }

  const closer = () => (
    <ConfirmationCloser
      bodyText='Changes you made will not be saved.'
      close={closeModal}
      confirmButtonText='Close'
      confirmClose={confirmClose}
      headingText='Close this window?'
    />
  )

  const deputyUnlockPermissionProps = {
    buttonText: 'Save',
    cancelButtonText: 'Cancel',
    // Merging here because DeputyUnlockPermission component is also used in the add deputy flow and that structure
    // of deputy is flat since the deputy doesn't exist yet.
    deputy: deputy.merge(deputy.get('ownable')),
    deputies: props.deputies,
    onCancel: closeModal,
    onChange: isFormDirty => setConfirmClose(isFormDirty),
    onSubmit: submit,
    processing
  }

  if (showDeputyUnlockConfirmation) {
    return (
      <DeputyUnlockPermissionsConfirmation
        deputies={props.deputies}
        name={deputyName}
        firstName={deputy.getIn(['ownable', 'first-name'])}
        exit={props.exit}
      />
    )
  }

  return (
    <Modals.PopUpModalLarge
      className='forms-playground manage-deputy-unlock-permissions-controller'
      closerComponent={closer}
      data-testid='manage-deputy-unlock-permissions-controller'
      showModal>
      <DeputyUnlockPermission {...deputyUnlockPermissionProps}>
        <ModalHeader
          heading={`Choose ${addPossessive(deputyName)} unlock permissions`}
          banner='Permissions'
        />
      </DeputyUnlockPermission>
    </Modals.PopUpModalLarge>
  )
}

ManageDeputyUnlockPermissionsController.propTypes = {
  deputies: PropTypes.instanceOf(List),
  exit: PropTypes.func,
  params: PropTypes.object,
  userConfig: PropTypes.instanceOf(Map)
}

export default ManageDeputyUnlockPermissionsController
