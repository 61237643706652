import PropTypes from 'prop-types'

import {useState} from 'react'

import Closer from '../shared_components/core/closer/Closer'
import ConfirmationModal from '../shared_components/mui_base_components/modals/confirmation_modal/ConfirmationModal'

const ConfirmationCloser = props => {
  const {
    bodyText,
    close,
    confirmButtonText,
    confirmClose,
    headingText
  } = props

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const closeModal = () => {
    confirmClose ? setIsConfirmationModalOpen(true) : close()
  }

  return (
    <div data-testid='confirmation-closer'>
      <Closer closer={closeModal} />
      <ConfirmationModal
        bodyText={bodyText}
        confirmButtonText={confirmButtonText}
        headingText={headingText}
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirmation={close}
        open={isConfirmationModalOpen}
      />
    </div>
  )
}

ConfirmationCloser.propTypes = {
  bodyText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  close: PropTypes.func,
  confirmButtonText: PropTypes.string,
  confirmClose: PropTypes.bool,
  headingText: PropTypes.string
}

export default ConfirmationCloser
