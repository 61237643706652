import Select, {components} from 'react-select'
import {forwardRef} from 'react'

import selectedOptionIcon from '../../../../../../dashboard/assets/check-mark-icon.svg'
import styles from './permissionSelectStyles'

const {Option} = components

// eslint-disable-next-line react/display-name
const PermissionSelect = forwardRef((props, ref) => {
  const customOption = innerProps => (
    <Option {...innerProps}>
      <div style={{alignItems: 'center', display: 'flex'}}>
        {innerProps.isSelected &&
          <img
            alt='selected option icon'
            src={selectedOptionIcon}
            style={{height: 15, marginRight: 13}}
          />
        }
        {innerProps.data.label}
      </div>
    </Option>
  )

  return (
    <Select
      {...props}
      className='permission-select'
      components={{Option: customOption}}
      isSearchable={false}
      formatOptionLabel={
        (option, {context}) => (
          context === 'menu' ? option.label : option.selectedLabel
        )
      }
      openMenuOnClick
      ref={ref}
      styles={styles}
    />
  )
})

export default PermissionSelect
