import PropTypes from 'prop-types'

import {List} from 'immutable'
import {useEffect, useState} from 'react'
import {useForm, FormProvider, Controller} from 'react-hook-form'

import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'
import Button from '../../../../../shared_components/mui_base_components/button/Button'
import ManageItemPermissions from './ManageItemPermissions'
import PermissionSelect from '../../permissions/specific_item/shared_components/PermissionSelect'

import warningIcon from '../../../../assets/warning-icon-black.svg'
import {existingPermissionOptions} from '../../../../../lib/deputyPermissionTools'

import './managePermissionsForm.scss'

const options = ([{value: ''}]).concat(existingPermissionOptions)

const permissionByOption = ({permissions, option}) =>
  Object.values(permissions).every(permission =>
    permission.value === option.value
  )

const selectAllOption = permissions => {
  if (permissionByOption({permissions, option: options[1]}))
    return options[1]
  else if (permissionByOption({permissions, option: options[2]}))
    return options[2]
  else if (permissionByOption({permissions, option: options[3]}))
    return options[3]
  else
    return options[0]
}

const ManagePermissionsForm = props => {
  const methods = useForm({
    defaultValues: {
      selectAll: selectAllOption(props.permissions),
      ...props.permissions
    }
  })

  const {
    control,
    formState: {isDirty},
    getValues,
    handleSubmit,
    setValue,
    watch
  } = methods

  const [
    isSaveButtonDisabled, setIsSaveButtonDisabled
  ] = useState(!props.isAddDeputy)

  const permissionValues = getValues(Object.keys(props.permissions))
  const selectAllValue = watch('selectAll')

  useEffect(() => {
    if (selectAllValue.value &&
      Object.values(permissionValues).some(
        permission => permission.value !== selectAllValue.value
      )) {
      setValue('selectAll', options[0], {shouldDirty: true})
    } else if (!selectAllValue.value) {
      const selectAllPermission = selectAllOption(permissionValues)

      if (selectAllValue.value !== selectAllPermission.value)
        setValue('selectAll', selectAllPermission, {shouldDirty: true})
    }
  }, [permissionValues, selectAllValue, setValue])

  useEffect(() => {
    if (props.isAddDeputy) return

    setIsSaveButtonDisabled(!isDirty)

    props.onChange(isDirty)
  }, [isDirty])

  const handleSelectAllChange = updatedValue => {
    Object.entries(props.permissions).forEach(([key, value]) =>
      setValue(key, {...value, ...updatedValue}, {shouldDirty: true})
    )
  }

  return (
    <div
      className='manage-permissions-form corp-max-deputies'
      data-testid='manage-permissions-form'>
      {props.children}
      {
        !props.everplanHasResponses &&
          <section className='empty-item-warning-text flex-container'>
            <img src={warningIcon} alt='Warning' />
            <span>You have not added any information to your Everplan.</span>
          </section>
      }
      {
        !props.itemsWithResponsesByCategory.isEmpty() && (
          <FormProvider {...methods} >
            <div className='share-all-note'>
            You can update permissions all at once or adjust them for each
             individual item. You can also share items now or after death—if you
              choose “after death” you’ll need to pick at least one person to
              unlock these items.
            </div>
            <div className='share-all-container'>
              <h3>All</h3>
              <Controller
                name='selectAll'
                control={control}
                render={({field}) => (
                  <PermissionSelect
                    {...field}
                    classNamePrefix='all-permissions-select'
                    filterOption={option => !!option.value}
                    onChange={value => {
                      field.onChange(value)
                      handleSelectAllChange(value)
                    }}
                    options={options}
                  />
                )}
              />
            </div>
            {
              props.itemsWithResponsesByCategory.map(category => (
                <div key={`${category.get('id')}${category.get('name')}`}>
                  <div className='category-section' >
                    <h3>{category.get('name')}</h3>
                    {
                      category.get('items').map(itemResponse => (
                        <ManageItemPermissions
                          {...props}
                          key={itemResponse.get('id')}
                          itemResponse={itemResponse}
                        />
                      ))
                    }
                  </div>
                </div>
              ))
            }
          </FormProvider>
        )
      }
      <ButtonGroup>
        <Button
          color='cancel'
          onClick={props.onCancel}>
          {props.cancelButtonText}
        </Button>
        <Button
          data-testid='submit-button'
          disabled={isSaveButtonDisabled}
          loading={props.processing}
          onClick={handleSubmit(props.onSubmit)}>
          {props.buttonText}
        </Button>
      </ButtonGroup>
    </div>
  )
}

ManagePermissionsForm.defaultProps = {
  buttonText: 'Next',
  cancelButtonText: 'Back'
}

ManagePermissionsForm.propTypes = {
  buttonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  everplanHasResponses: PropTypes.bool,
  itemsWithResponsesByCategory: PropTypes.instanceOf(List),
  isAddDeputy: PropTypes.bool,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  permissions: PropTypes.object,
  processing: PropTypes.bool
}

export default ManagePermissionsForm
