import classnames from 'classnames'
import PropTypes from 'prop-types'

import {Fragment} from 'react'

import Select from '../../../../../shared_components/forms/v3/Select'

import './listWithWaitTimeSelect.scss'

const ListWithWaitTimeSelect = props => {
  const {
    children,
    className,
    name,
    options,
    selectPosition
  } = props

  return (
    <ul
      className={
        classnames('list-with-wait-time-select', className)
      }>
      {
        [].concat(children).map(
          (child, index) => (
            <Fragment key={index}>
              <li className='item'>{child}</li>
              {
                selectPosition === index &&
                <li>
                  <Select
                    ariaLabel='Select a wait time for reporting death'
                    name={name}
                    options={options}
                  />
                </li>
              }
            </Fragment>
          )
        )
      }
    </ul>
  )
}

ListWithWaitTimeSelect.propTypes = {
  name: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  selectPosition: PropTypes.number,
  selectValue: PropTypes.string
}

export default ListWithWaitTimeSelect
