import {connect} from 'react-redux'
import {List, Map} from 'immutable'
import {useMutation} from '@apollo/client'
import {useState, useMemo, useEffect} from 'react'
import PropTypes from 'prop-types'

import ConfirmationCloser from '../../../../../shared_components/ConfirmationCloser'
import {ModalHeader} from '../../../../../shared_components/core/modals/ModalHeaders'
import ManagePermissionsForm from './ManagePermissionsForm'
import MixpanelLogger from '../../../../../lib/MixpanelLogger'
import Modals from '../../../../../shared_components/core/modals/Modals'
import NewLoader from '../../../../../shared_components/NewLoader'

import {addPossessive} from '../../../../../lib/tools'
import {categoriesWithItemResponseGroupMaps} from '../../../../../lib/plan_data/itemResponsesHelper'
import {defaultDeputyPermissions, formateDeputyPermissions} from '../../../../../lib/deputyPermissionTools'
import {deputyManageableItems} from '../../../../../lib/corpMaxDeputyTools'
import {findResourceById, filterRawResourceByAttributeId, findFirmConfigByFirmOwnership} from '../../../../../lib/plan_data/userData'
import {humanizedFullName} from '../../../../../lib/nameTools'
import {SPECIFIC_ITEMS_DEPUTIES} from '../../../../../graphql/queries/deputy'
import {UPDATE_DEPUTY_PERMISSIONS} from '../../../../../graphql/mutations/deputy'
import {useDeputyData} from '../../../../../lib/hooks'
import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'
import {useScoreLedgerEventLogger} from '../../../../../lib/ScoreLedgerEventLogger'
import Logger from '../../../../../lib/NewLogger'

import './managePermissions.scss'

export const ManagePermissions = props => {
  const [confirmClose, setConfirmClose] = useState()
  const [processing, setProcessing] = useState(false)

  const {alwaysNotify} = useNotificationContext()
  const {logWithDelayedNotification} = useScoreLedgerEventLogger(props.userConfig)
  const {specificItemsDeputies} = useDeputyData(props.userConfig.get('everplan-id'))

  const everplanHasResponses = useMemo(() => props.itemResponses.some(itemResponse => !itemResponse.get('response-groups').isEmpty()), [])

  const permissions = useMemo(() =>
    defaultDeputyPermissions({specificItemsDeputies, deputyId: props.deputy.get('id'), itemsWithResponsesByCategory: props.itemsWithResponsesByCategory}),
  [])

  const [updateDeputyPermissions] = useMutation(UPDATE_DEPUTY_PERMISSIONS, {
    awaitRefetchQueries: true,
    refetchQueries: [SPECIFIC_ITEMS_DEPUTIES]
  })

  const logEvent = name => {
    Logger.log({
      name,
      payload: {
        context: 'deputy_permissions_all_items'
      }
    })
  }

  const closeModal = () => {
    logEvent('dismissed_deputy_permissions')
    props.exit()
  }

  const deputyName = humanizedFullName(props.deputy.get('ownable'))

  const submit = updatedPermissions => {
    setProcessing(true)

    logEvent('saved_deputy_permissions')

    updateDeputyPermissions({
      variables: {
        input: formateDeputyPermissions(updatedPermissions),
        deputyId: props.deputy.get('id')
      },
      onCompleted: () => {
        alwaysNotify.shortSuccess(`${addPossessive(deputyName)} permissions were updated`)
        logWithDelayedNotification.updatedDeputyPermissions()
        props.exit()
      }
    })
  }

  const closer = () => (
    <ConfirmationCloser
      bodyText='Changes you made will not be saved.'
      close={closeModal}
      confirmButtonText='Close'
      confirmClose={confirmClose}
      headingText='Close this window?'
    />
  )

  const modalNote = () => {
    if (props.manageableItemResponses.size !== props.itemResponses.size)
      return `Note: For compliance purposes, only the items shown below can be shared with ${deputyName}.`
  }

  const managePermissionsFormProps = {
    ...props,
    buttonText: 'Save',
    cancelButtonText: 'Cancel',
    className: 'manage-sad-tooltip',
    everplanHasResponses,
    onCancel: closeModal,
    onChange: isFormDirty => setConfirmClose(isFormDirty),
    onSubmit: submit,
    permissions,
    processing
  }

  useEffect(() => {
    const payload = {}
    const context = props.location.query.context || props.context

    if (context)
      payload.context = context

    MixpanelLogger.track('view_deputy_response_group_permissions', payload)
  }, [])

  return (
    <>
      <Modals.PopUpModalLarge
        className='forms-playground manage-permissions'
        closerComponent={closer}
        data-testid='manage-permissions'
        showModal>
        {
          permissions ?
            <ManagePermissionsForm {...managePermissionsFormProps}>
              <ModalHeader
                banner='Permissions'
                heading={`Choose ${addPossessive(deputyName)} item permissions`}
                note={modalNote()}
              />
            </ManagePermissionsForm> :
            <NewLoader loading />
        }
      </Modals.PopUpModalLarge>
    </>
  )
}

ManagePermissions.propTypes = {
  context: PropTypes.string,
  deputy: PropTypes.instanceOf(Map),
  exit: PropTypes.func,
  itemResponses: PropTypes.instanceOf(List),
  itemsWithResponsesByCategory: PropTypes.instanceOf(List),
  loading: PropTypes.bool,
  location: PropTypes.shape({
    query: PropTypes.object
  }),
  manageableItemResponses: PropTypes.instanceOf(List),
  userConfig: PropTypes.instanceOf(Map)
}


const mapStateToProps = (state, ownProps) => {
  const firmConfig = findFirmConfigByFirmOwnership({firmOwnerships: state.api['firm-ownerships'], firmConfigs: state.api['firm-configs'], userConfig: ownProps.userConfig})
  const loading = ownProps.userConfig.get('client') && firmConfig.isEmpty()
  const deputy = findResourceById({resourceList: ownProps.deputies, id: ownProps.deputyOwnershipId || ownProps.params.deputyOwnershipId})
  const manageableItemResponses = deputyManageableItems({isFirmUser: deputy.getIn(['ownable', 'firm-user']), itemResponses: ownProps.itemResponses, firmConfig})

  const itemsWithResponsesByCategory = loading ?
    List() :
    categoriesWithItemResponseGroupMaps({
      categories: state.categories,
      itemResponses: manageableItemResponses
    })

  return ({
    deputy,
    manageableItemResponses,
    itemsWithResponsesByCategory,
    loading,
    itemViews: filterRawResourceByAttributeId({
      attribute: 'everplan-id',
      id: ownProps.userConfig.get('everplan-id'),
      rawResource: state.api['item-views']
    })
  })
}

export default connect(mapStateToProps)(ManagePermissions)
