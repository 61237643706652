import PropTypes from 'prop-types'

import {Controller, FormProvider, useForm} from 'react-hook-form'
import {useEffect, useState} from 'react'
import {Map, List} from 'immutable'

import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material'

import {TextGutterMedium} from '../../blocks/Texts'
import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'
import Button from '../../../../../shared_components/mui_base_components/button/Button'
import DeputyWaitTimeSelect from './DeputyWaitTimeSelect'
import NoUnlockPermissionInformation from './NoUnlockPermissionInformation'
import UnorderedList from '../../../../../shared_components/UnorderedList'
import {
  verificationPeriodMinutesToString,
  verificationPeriodStringToMinutes
} from '../../../../../lib/deathVerificationPeriodTools'

import './deputyUnlockPermission.scss'

const defaultRadioSelection = deputy => {
  if (deputy.get('death-reporter'))
    return 'yes'
  else if (deputy.get('death-reporter') === false)
    return 'no'
  else
    return ''
}

const DeputyUnlockPermission = props => {
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true)
  const [showMessage, setShowMessage] = useState(false)

  const methods = useForm({
    defaultValues: {
      unlockOption: defaultRadioSelection(props.deputy),
      waitTimeOption: {
        value: verificationPeriodMinutesToString(
          props.deputy.get('death-verification-period')
        ) || '48 hours'
      }
    }
  })

  const {
    control,
    formState: {isDirty},
    handleSubmit,
    resetField,
    watch
  } = methods

  const unlockOptionValue = watch('unlockOption')

  const shouldShowNoUnlockerPermissionInformation = showMessage ||
    (!props.deputy.get('death-reporter') && (unlockOptionValue === 'no'))
  const shouldShowWaitTimeSelect = unlockOptionValue === 'yes' && !showMessage

  useEffect(() => {
    if (props.isAddDeputy) {
      setIsSubmitButtonDisabled(!isDirty && !props.deputy.has('death-reporter'))
    } else {
      setIsSubmitButtonDisabled(!isDirty)
      props.onChange(isDirty)
    }
  }, [isDirty, props.deputy, props.isAddDeputy, props.onChange])

  useEffect(() => {
    if (props.isAddDeputy) return

    if (unlockOptionValue === 'no')
      resetField('waitTimeOption.value')
  }, [unlockOptionValue])

  const onSubmit = formData => {
    const deathReporterValue = formData.unlockOption === 'yes'

    props.onSubmit({
      'death-reporter': deathReporterValue,
      'death-verification-period': deathReporterValue ?
        verificationPeriodStringToMinutes(formData.waitTimeOption.value) :
        null
    })
  }

  return (
    <div
      className='deputy-unlock-permission'
      data-testid='deputy-unlock-permission'>
      {props.children}
      <FormProvider {...methods}>
        <Box
          component='form'
          data-testid='deputy-unlock-permission-form'
          onSubmit={handleSubmit(onSubmit)}>
          <TextGutterMedium>
            <FormControl>
              <Controller
                name='unlockOption'
                control={control}
                render={({field}) => (
                  <RadioGroup
                    {...field}
                    aria-labelledby='radio-buttons-group'
                    onChange={event => {
                      field.onChange(event)
                      setShowMessage(event.target.value === 'no')
                    }}>
                    <FormControlLabel
                      data-testid='allow-unlock-radio-button'
                      value='yes'
                      control={<Radio />}
                      label={
                        `Allow ${props.deputy.get('first-name')} to unlock my "after death" items`
                      }
                    />
                    <UnorderedList
                      className='additional-info'
                      children={[
                        `${props.deputy.get('first-name')} will be responsible for logging into Everplans after your death and requesting that your "after death" items be unlocked.`,
                        'Once unlocked, your "after death" items will be unlocked for all deputies who have been granted "after death" access.'
                      ]}
                    />
                    <DeputyWaitTimeSelect
                      deputy={props.deputy}
                      displayWaitTimeSelect={shouldShowWaitTimeSelect}
                      name='waitTimeOption'
                    />
                    <FormControlLabel
                      data-testid='do-not-allow-unlock-radio-button'
                      value='no'
                      control={<Radio />}
                      label='Do not allow'
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
            <NoUnlockPermissionInformation
              deputies={props.deputies}
              displayInfo={shouldShowNoUnlockerPermissionInformation}
            />
          </TextGutterMedium>
          <ButtonGroup>
            <Button
              color='cancel'
              onClick={props.onCancel}>
              {props.cancelButtonText}
            </Button>
            <Button
              data-testid='submit-button'
              disabled={isSubmitButtonDisabled}
              loading={props.processing}
              type='submit'>
              {props.buttonText}
            </Button>
          </ButtonGroup>
        </Box>
      </FormProvider>
    </div>
  )
}

DeputyUnlockPermission.defaultProps = {
  buttonText: 'Next',
  cancelButtonText: 'Back'
}

DeputyUnlockPermission.propTypes = {
  buttonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  deputies: PropTypes.instanceOf(List),
  deputy: PropTypes.instanceOf(Map),
  isAddDeputy: PropTypes.bool,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  processing: PropTypes.bool
}

export default DeputyUnlockPermission
