/** @class DeputyWaitTimeSelect
 *
 * @desc
 * Component that allows an Everplanner to select a `death_verification_period`
 * for a Deputy.
 *
 * @todo Remove the `OPTIONS_FOR_QA` and conditional after we complete QA for the eventual `household_death_reporting`.
 */
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import ListWithWaitTimeSelect from './ListWithWaitTimeSelect'

import {TextGutterMedium} from '../../blocks/Texts'
import {addPossessive} from '../../../../../lib/tools'
import featureFlags from '../../../../../lib/FeatureFlags'
import {
  verificationPeriodStrings
} from '../../../../../lib/deathVerificationPeriodTools'

const OPTIONS = verificationPeriodStrings.map(waitTime => ({id: waitTime, label: waitTime, value: waitTime}))
const OPTIONS_FOR_QA = [{id: 'Sixty seconds', label: 'Sixty seconds', value: 'Sixty seconds'}].concat(OPTIONS)

const DeputyWaitTimeSelect = ({deputy, displayWaitTimeSelect, ...otherProps}) => (
  <div data-testid='deputy-wait-time-select'>
    {
      displayWaitTimeSelect &&
      (
        <TextGutterMedium>
          <strong className='title'>Data security setting:</strong>
          <ListWithWaitTimeSelect
            {...otherProps}
            selectPosition={1}
            options={(INTERNAL_ONLY && featureFlags.isEnabled('quality_assurance')) ? OPTIONS_FOR_QA : OPTIONS}
            className='additional-info'
            children={[
              'To prevent against accidental or unwanted unlocking, once we receive an unlock request, we will attempt to contact you via email and give you the opportunity to disallow it.',
              `${addPossessive(deputy.get('first-name'))} wait time while we attempt to contact you will be:`,
              `If we don't hear from you by the end of the wait time, your "after death" items will be unlocked for ${deputy.get('first-name')} and all other deputies who have been granted "after death" access.`
            ]}
          />
        </TextGutterMedium>
      )
    }
  </div>
)

DeputyWaitTimeSelect.propTypes = {
  deputy: PropTypes.instanceOf(Map),
  displayWaitTimeSelect: PropTypes.bool
}

export default DeputyWaitTimeSelect
