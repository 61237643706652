import PropTypes from 'prop-types'
import {List} from 'immutable'

import {TextGutterMedium} from '../../blocks/Texts'

import {humanizedFullNamesWithConjunction} from '../../../../../lib/nameTools'

import './noUnlockPermissionInformation.scss'


const NoUnlockPermissionInformation = props => {
  const deathReporterDeputies = props.deputies.filter(deputy => deputy.get('death-reporter')).map(deputy => deputy.get('ownable'))

  const deathReporterNames = humanizedFullNamesWithConjunction(deathReporterDeputies)

  const withDeathReportersText = `As a reminder, you have given ${deathReporterNames} permission to request the unlocking of your "after death" items.`

  const withoutDeathReportersText = "You currently do not have anyone to unlock your \"after death\" items. We'll prompt you to make the selection later."

  const deathReporterPermissionText = () => {
    if (deathReporterDeputies.isEmpty())
      return withoutDeathReportersText
    else
      return withDeathReportersText
  }


  return (
    <div
      className='no-unlock-permission-information'
      data-testid='no-unlock-permission-information'>
      {
        props.displayInfo &&
          <TextGutterMedium>{deathReporterPermissionText()}</TextGutterMedium>
      }
    </div>
  )
}


NoUnlockPermissionInformation.propTypes = {
  deputies: PropTypes.instanceOf(List),
  displayInfo: PropTypes.bool
}


export default NoUnlockPermissionInformation
